import { useEffect } from "react";
import './styles.module.scss'

export const SubscriberForm = () => {
  useEffect(() => {
    (function(w, d, t, h, s, n) {
      w.FlodeskObject = n;
      var fn = function() {
        (w[n].q = w[n].q || []).push(arguments);
      };
      w[n] = w[n] || fn;
      var f = d.getElementsByTagName(t)[0];
      var v = '?v=' + Math.floor(new Date().getTime() / (120 * 1000)) * 60;
      var sm = d.createElement(t);
      sm.async = true;
      sm.type = 'module';
      sm.src = h + s + '.mjs' + v;
      f.parentNode.insertBefore(sm, f);
      var sn = d.createElement(t);
      sn.async = true;
      sn.noModule = true;
      sn.src = h + s + '.js' + v;
      f.parentNode.insertBefore(sn, f);
    })(window, document, 'script', 'https://assets.flodesk.com', '/universal', 'fd');
    window.fd('form', {
      formId: '65ba9b2a0f1ef3462a419ea6'
    });
  }, [])
  
  return (
    <div></div>
  );
};
