import { Helmet } from "react-helmet";

import { HomeBanner } from "component/Home/HomeBanner";
import { StemEducation } from "component/Home/StemEducation";
import { KeyInitiatives } from "component/Home/KeyInitiatives";
import { Stories } from "component/Home/Stories";
import { StudentMapCounter } from "component/Home/StudentMapCounter";
import { StemWorkforce } from "component/Home/StemWorkforce";
import { UpcomingPrograms } from "component/Home/UpcomingPrograms";
import { LearningModel } from "component/Home/LearningModel";
import { SchoolPartners } from "component/Home/SchoolPartners";
import { Testimonial } from "component/Testimonial/Testimonial";
import { InTheNews } from "component/Home/InTheNews";
import { News } from "component/Common/News/News";
import { PartnersAndLatestNews } from "component/Home/PartnersAndLatestNews";
import { SubscriberForm } from "component/Common/SubscriberForm";
import { PastPrograms } from "component/Home/PastPrograms";

export const Home = () => {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="The Coding School awarded $3 million grant"
        />
      </Helmet>
      <div className="homePage">
        <HomeBanner />
        <StemEducation />
        <KeyInitiatives />
        <UpcomingPrograms />
        {/* <PastPrograms /> */}
        <Stories />
        <StudentMapCounter />
        <LearningModel />
        <Testimonial />
        <StemWorkforce />
        <SchoolPartners />
        <PartnersAndLatestNews />
        <InTheNews />
        <News />
        <SubscriberForm />
      </div>
    </>
  );
};
